@import "../../assets/styles/breakpoints.scss";

.formGrid {
	display: grid;
	grid-gap: 20px;
	height:100%;
  overflow-y:auto;
  overflow-x:hidden;
}

.twoColumn {
	display: grid;
	grid-template-columns: calc(50% - 10px) calc(50% - 10px);
	column-gap: 20px;

	@include to($breakpoint-md) {
		display: flex;

		> div {
			margin-right: 20px;
		}
	}
}
