@font-face {
	font-family: 'Lato-Regular';
	src: url('../fonts/Lato-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/*----------------------------------------------------------
Sass variables & Mixins
----------------------------------------------------------*/

$font: Roboto, arial, Helvetica, Sans-Serif;
$iconFont:"maples-font";

/* Common Colors */
$primaryColor: #1B99D6 ;
$textMain: #323549;
$textSeconday: #717171;
$borderColour: #919191;
$inActive:#d2d2d2;
$green :#27AE60;
$red: #EB5757;
$pink: #B55A85;
$purple: #846CA9;
$teal: #307E9E;
$violet: #6475AD;