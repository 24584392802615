@import "../../assets/styles/mixins.scss";

.chart {
	position: relative;
	align-items: center;
	display: grid;
	grid-template-columns: 33.3% 33.3% 33.3%;
	height: 100%;
	overflow: auto;
	background:
	/* Shadow covers */
	linear-gradient(#F2F2F2 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), #F2F2F2 70%) 0 100%,
	/* Shadows */
	radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
	background:
	/* Shadow covers */
	linear-gradient(#F2F2F2 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), #F2F2F2 70%) 0 100%,
	/* Shadows */
	radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
	background-repeat: no-repeat;
	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
	background-attachment: local, local, scroll, scroll;
}

.column {
	display: grid;
	grid-template-rows: auto auto;
	position: relative;
	left: 0;
	opacity: 1;
	transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out 0.5s, grid-template-rows 0.5s ease-in-out 0.5s;

	h3, h4 {
		display: block;
		color: $pink;
		margin-bottom: 0;
		margin-top: 0;
		position: relative;
	}

	h4 {
		margin: 5px 0 0;
		font-family: 'Lato-Regular', arial, Helvetica, Sans-Serif;

		span {
			font-size: 38px;
		}
	}

	.investorTitle h3 {
		color: $teal !important;
		margin-bottom: 20px !important;
	}

	> div {
		position: relative;
		transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out, left 0.5s ease-in-out;
		max-height: 2000px;
		left: 0;
	}

	.sectiondescription {
		font-size: 12px;
		margin: 11px 0 0;
		font-family: 'Lato-Regular', arial, Helvetica, Sans-Serif;
		line-height: 100%;
	}
}

.focusDirectors {

	> div {
		opacity: 0;
		max-height: 0;
	}

	.cpSection {
		opacity: 1;
		max-height: 2000px;
		left: -100%;
	}
}

.focusUBOs {

	> div {
		opacity: 0;
		max-height: 0;
	}

	.uboSection {
		opacity: 1;
		max-height: 2000px;
		left: -100%;
	}
}

.focusSLCP {

	> div {
		opacity: 0;
		max-height: 0;
	}

	.ccpSection {
		opacity: 1;
		max-height: 2000px;
		left: -200%;
	}
}

.focusSLBO {

	> div {
		opacity: 0;
		max-height: 0;
	}

	.uboSection {
		opacity: 1;
		max-height: 2000px;
		left: -200%;
	}
}

.titleSection {
	position: relative;
	align-items: center;
	display: flex;
	margin-bottom: 0;

	&:before {
		content: '';
		position: absolute;
		left: -12px;
		top: 12px;
		border-left: 10px solid $pink;
		border-right: none;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		transform: scaleY(0);
		transform-origin: right center;
		transition: transform 0.3s ease-in-out;
	}
}

.buttonBar {
	border: 1px solid $pink;
	border-radius: 20px;
	margin-left: 30px;

	> button {
		color: $pink;
		font-size: 24px;
		width:31px;
		&:hover{
		  color:$textMain!important;
		}
	}
}

.first {
	align-items: center;
	grid-template-rows: 1fr;
	min-height: 100%;

	&:before {
		display: none;
	}
}

.section.cpSection {

	.buttonBar {
		border-color: $pink;

		> button {
			color: $pink;
		}
	}
}

.section.ccpSection {
	.titleSection {
		&:before {
			border-left-color: $violet;
		}
	}

	h3, h4 {
		color: $violet;
	}

	.buttonBar {
		border-color: $violet;

		> button {
			color: $violet;
		}
	}
}

.section.uboSection {
	.titleSection {
		&:before {
			border-left-color: $purple;
		}
	}

	h3, h4 {
		color: $purple;
	}

	.buttonBar {
		border-color: $purple;

		> button {
			color: $purple;
		}
	}
}

.nodes, .investorNode {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
}

.nodes {
	margin-bottom:40px;
	margin-top: 15px;
	position:relative;
}

.focusColumn1 {
	> div {
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
	}

	.connectorWrap {
		opacity: 0;
	}

	> .first {
		opacity: 1;
	}
}

.focusColumn2 {
	> div {
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
	}

	.connectorWrap {
		opacity: 0;
	}

	> .second {
		opacity: 1;
	}
}

.focusColumn3 {
	> div {
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
	}

	.connectorWrap {
		opacity: 0;
	}

	> .third {
		opacity: 1;
	}
}

.connectorWrap {
	position: absolute;
	left: 200px;
	width: calc(33% - 200px);
	transform-origin: bottom left;
	overflow: visible;
	opacity: 1;
}
.connector {
	fill: none;
	stroke-width: 3px;
	stroke-dasharray: 800;
	stroke-dashoffset: 0;
	animation: dash 2s ease-out forwards;
}

.animatedIn {
	.second {
		.titleSection:before {
			transform: scaleY(1);
		}
	}

	.connectorWrap[data-source="sourceEl"] {
		transition: opacity 1s ease-in-out, transform 1s ease-in-out, height 1s ease-in-out, top 1s ease-in-out;

		.connector {
			animation: none;
		}
	}
}

.animatedIn2 {
	.third {
		.titleSection:before {
			transform: scaleY(1);
		}
	}

	.connectorWrap[data-source="column2source"] {
		transition: opacity 1s ease-in-out;

		.connector {
			animation: none;
		}
	}
}

.chart.hideConnectors {
	.connectorWrap {
		opacity: 0;
		transition: opacity 1s ease-in-out;
	}

	:global(.chartNode .menuButton) {
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
	}
}

.chart.hideL2Connectors .connectorWrap.l2connectorWrap {
	opacity: 0;
	transition: opacity 1s ease-in-out;
}

.buttonText {
	color: $primaryColor;
	cursor:pointer;
	background: none;
	border: 0;
	margin-bottom: 17px;
	outline: none;
	padding: 0;

	&:hover {
		color: $textMain;
	}
}

.submitBar{
  align-items:center;
  background:white;
  border-top:1px solid #ddd;
  bottom:0;
  box-sizing:border-box;
  display:flex;
  justify-content:flex-end;
  left:0;
  padding:10px 20px;
  position:absolute;
  width:100%;
  > span{
	margin-right:20px;
  }
}

@keyframes dash {
	from {
		stroke-dashoffset: 800;
	}

	to {
		stroke-dashoffset: 0;
	}
}

@keyframes fadeInOut {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	90% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.tourGuide {
	background-color: #323549 !important;
	color: #FFF !important;
	margin-top: 15px;
	animation: fadeIn 1s ease-in-out forwards;
}

.tourright, .tourleft {
	&:before {
		content: '';
		width: 0;
		height: 0;
		border-top: 13px solid transparent;
		border-bottom: 13px solid transparent;
		border-left: none;
		border-right: 15px solid #323549;
		position: absolute;
		top: 35px;
		left: -14px;
	}

	.buttonwrap {
		text-align: right;

		.tourbutton {
			color: #FFF;
		}

		.tourbuttonPrimary {
			color: #FFF;
			background: $primaryColor;
		}
	}

	p {
		margin-top: 0;
	}
}

.tourleft {
	&:before {
		border-top: 13px solid transparent;
		border-bottom: 13px solid transparent;
		border-right: none;
		border-left: 15px solid #323549;
		position: absolute;
		left: auto;
		right: -14px;
	}
}

.shiftUp20 {
	&:before {
		top: 24px;
	}
}

.tourMask {
	opacity: 0;
}

:global(.chartNode) {
	height:58px;
	margin-bottom:15px;
	&:last-child{
		margin-bottom:0;
	}
}
/*Node Animations*/
:global(.cpNode-enter), :global(.boNode-enter) {
	transform: translateX(-200px);
}

:global(.cpNode-enter-active), :global(.boNode-enter-active) {
	transform: translateX(0);
	transition: transform 2s cubic-bezier(0.34,1.56,0.64,1);
}

:global(.cpNode-exit-active), :global(.boNode-exit-active) {
	border:0!important;
	height: 0;
	margin-bottom: 0;
	opacity: 0;
	transform: translateX(100px);
	transition: margin-bottom 1s, height 1s, opacity 1s, transform 1s cubic-bezier(0.34,1.56,0.64,1);
}

:global(.cpEmptyNode-enter) {
	transform: translateX(-200px);
}

:global(.cpEmptyNode-enter-active) {
	transform: translateX(0);
	transition: transform 2s cubic-bezier(0.34,1.56,0.64,1);
}


:global(.cpEmptyNode-exit-active) {
	max-height:0;
	opacity: 0;
	transition: opacity 2s cubic-bezier(0.34,1.56,0.64,1);
}