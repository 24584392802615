.layout
{
  align-items:center;
  column-gap:80px;
  display:grid;
  grid-template-columns:1fr 1fr;
  height:100%;
  img{
	width:100%;
  }
}

.content{
  margin-bottom:30px;
}