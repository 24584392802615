@import "../assets/styles/animations.scss";

.homeLayout {
	grid-column-gap: 80px;
	column-gap: 80px;
	display: grid;
	grid-template-columns: auto calc(50% - 40px);
	grid-row-gap: 40px;
	align-items: center;
	height: 100%;
}

.unmounting > div {
	animation-name: slideOutToLeft;
	animation-duration: 0.4s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}
.unmounting > div + div {
	animation-delay: 200ms;
}

.welcomeimage {
	width: 90%;
	vertical-align: central;
}

.intro {
	display: grid;
	grid-template-rows: auto minmax(0, 1fr) auto;
	max-height: 100%;
	min-height: 0;

	> h1 {
		margin-bottom: 40px;
		line-height: 62px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow-wrap: break-word;
		overflow: hidden;
	}

	h1, p {
		span {
			color: #1B99D6;
		}
	}

	.content {
		margin-bottom: 40px;
		min-height: 0;
		overflow: auto;
	}

	p {
		color: #717171;
		font-size: 22px;
		font-weight: 300;
		line-height: 26px;
		margin: 0 0 20px;
	}

	.openButton {
		padding: 13px;
		color: #FFF;
		background: #1B99D6;
		border: none;
		border-radius: 3px;
		font-size: 24px;

		span.arrowIcon {
			display: inline-block;
			font-family: maples-font;
			transform: rotate(90deg);
		}
	}
}

.stepperContainer{
  box-sizing:border-box;
  height:100%;
  padding:20px 30px 0;
}


@media only screen and (max-width: 860px) {
	.intro > h1 {
		-webkit-line-clamp: 2;
	}
}