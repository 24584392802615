@import "../../../assets/styles/formGrid.scss";

.textarea {
	height: 170px;
}

.pepButton{
  display:flex;
  > button:before{
	vertical-align:middle;
  }
}

.documentContainer{
  margin-top:20px;
}

.formGrid {
	> * {
		@for $i from 1 through 10 {
			&:nth-child(#{$i}) {
				animation-delay: 150ms * $i !important;
			}
		}
	}
}