﻿@import "~maples-styleguide/sass/mixins.scss";

.MuiTableCell-root.MuiTableCell-head {
  color: black;
  font-size: 1em;
}

.MuiLink-underlineHover {
  font-family: $font !important;

  &:hover {
    text-decoration: none !important;
  }
}

.MuiDialog-root {
  .MuiDialogContent-root {
    padding: 20px;
  }

  .MuiDialogTitle-root {
    border-bottom: 1px solid $border-color;
  }

  .MuiDialogActions-root {
    border-top: 1px solid $border-color;
  }
}

.MuiFormControl-root {
  .MuiFilledInput-root {
    background: white;
    border: 1px solid $border-color;

    &.Mui-focused, &:hover, .MuiSelect-select:focus {
      background: white;
    }
  }

  &.noLabel {
    .MuiFilledInput-input {
      padding-top: 10px;
    }

    &.datePickerFilter {
      .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
        margin-top: 0;
      }

      .MuiIconButton-root {
        padding: 0;
      }
    }
  }

  .MuiFilledInput-underline:after {
    border-color: $MaplesPrimary;
  }

  .MuiFormLabel-root {
    &.Mui-focused {
      color: $MaplesPrimary;

      &.MuiFormLabel-root.Mui-error {
        color: #f44336;
      }
    }
  }
}

.filterArea {
  .MuiInputBase-root {
    padding-right: 30px;
  }
}

.row {
  .MuiFormControl-root {
    margin-bottom: 20px;
  }
}

/*Tabs*/
.MuiTabs-root {
  margin-bottom: -1px;

  .tab {
    background: $tableHeaderRowColor;
    border: 1px solid $border-color;
    border-radius: 5px 5px 0 0;
    margin-right: 1px;
    min-width: 0;

    &.Mui-selected {
      background: white;
      border-bottom-color: white;
      color: $MaplesPrimary;
    }
  }

  .MuiTabs-indicator {
    top: 1px;
  }
}

.tabPanel {
  background: white;
  border: 1px solid $border-color;
  padding: 20px;
}


.MuiMenuItem-root{
  min-height:33px!important;
}