// ---------------------------------------------------------
// @Breakpoints
// ---------------------------------------------------------

$breakpoint-xl: 1440px;
$breakpoint-lg: 1200px;
$breakpoint-md: 992px;
$breakpoint-sm: 768px;
$breakpoint-xs: 0;

$breakpoints: ( "xl\\+" "screen and (min-width: #{$breakpoint-xl})", "lg\\+" "screen and (min-width: #{$breakpoint-lg})", "md\\+" "screen and (min-width: #{$breakpoint-md})", "sm\\+" "screen and (min-width: #{$breakpoint-sm})", "xs\\+" "screen and (min-width: #{$breakpoint-xs})", "xl-" "screen and (max-width: #{$breakpoint-xl - 1px})", "lg-" "screen and (max-width: #{$breakpoint-lg - 1px})", "md-" "screen and (max-width: #{$breakpoint-md - 1px})", "sm-" "screen and (max-width: #{$breakpoint-sm - 1px})", "lg" "screen and (min-width: #{$breakpoint-lg - 1px}) and (max-width: #{$breakpoint-xl - 1px})", "md" "screen and (min-width: #{$breakpoint-md - 1px}) and (max-width: #{$breakpoint-lg - 1px})", "sm" "screen and (min-width: #{$breakpoint-sm - 1px}) and (max-width: #{$breakpoint-md - 1px})", );

// ---------------------------------------------------------
// @TOC
// ---------------------------------------------------------

// + @General Media Query
// + @All Above Media Query
// + @All Under Media Query
// + @Between Two Devices Media Query

// ---------------------------------------------------------
// @General Media Query Mixin
// ---------------------------------------------------------

// Mixin used for custom rules that don't follow
// any of the following premade media queries.

@mixin mq($condition) {
	@media #{$condition} {
		@content;
	}
}

// ---------------------------------------------------------
// @All Above Media Query Mixin
// ---------------------------------------------------------

// Mixin used to match certain breakpoint
// and all devices above it.

@mixin from($breakpoint) {
	@media screen and (min-width: $breakpoint) {
		@content;
	}
}

// ---------------------------------------------------------
// @All Under Media Query Mixin
// ---------------------------------------------------------

// Mixin used to match all devices under certain breakpoint.

@mixin to($breakpoint) {
	@media screen and (max-width: $breakpoint - 1px) {
		@content;
	}
}

// ---------------------------------------------------------
// @Between Two Devices Media Query Mixin
// ---------------------------------------------------------

// Mixin used to match the devices between 2 breakpoints.

@mixin between($start, $end) {
	@media screen and (min-width: $start) and (max-width: $end - 1px) {
		@content;
	}
}
