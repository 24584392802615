@keyframes slideInFromRight {
	0% {
		transform: translateX(10%);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideInFromLeft {
	0% {
		transform: translateX(-10%);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideOutToLeft {
	0% {
		transform: translateX(0);
		opacity: 1;
	}

	100% {
		transform: translateX(-10%);
		opacity: 0;
	}
}

@keyframes slideOutToRight {
	0% {
		transform: translateX(0);
		opacity: 1;
	}

	100% {
		transform: translateX(5%);
		opacity: 0;
	}
}

@keyframes slideInFromBottom {
	0% {
		transform: translateY(5%);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideOutFromBottom {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(10%);
		opacity: 0;
	}
}
