@import "../../assets/styles/mixins.scss";
@import "../../assets/styles/formGrid.scss";
.addressItems{
  align-items:center;
  display:grid;
}
.hideMe{
  display:none!important;
}
.addressItem {
	align-items: center;
	border: 1px dashed $borderColour;
	border-radius: 10px;
	display: flex;
	padding: 20px;
	position: relative;

	&:first-child {
		margin-top: 0;
	}

	&:before {
		content: '';
		position: absolute;
		top: -1px;
		left: -1px;
		width: 100%;
		height: 100%;
		border: 1px solid $borderColour;
		border-radius: 10px;
		opacity: 0;
		transition: 1s opacity ease-in-out;
	}

	.addressSection {
		flex: 1;
		margin: 0 20px;

		.addressText, span {
			pointer-events: none;
		}

		button {
			position: relative;
			z-index: 2;
		}
	}

	h4 {
		margin: 0;
		&.addressTitle{
		  margin-bottom:5px;
		}
	}

	.addressText {
		color: $borderColour;
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 5px;
	}

	&.active {
		&:before {
			opacity: 1;
		}
	}
}
.circle {
	color: $textSeconday;
	position:relative;
	top:3px;
}
.activeCircle {
	color: $green;
	filter: grayscale(0);
	animation-name: bounceIn;
	animation-duration: 0.5s;
}

.location {
	color: $inActive;
	font-size: 2rem !important;
}
.inActive {
	color: $textSeconday;
}

.locationActive {
	color: $textMain;
}

.clickArea {
	display: block;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 1;
}

.twoColumn {
	grid-template-columns: calc(50% - 10px) calc(50% - 10px);
}

@keyframes bounceIn {
	from, 33%, 66%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	33% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1);
		transform: scale3d(1.1, 1.1, 1.1);
	}

	66% {
		-webkit-transform: scale3d(0.9, 0.9, 0.9);
		transform: scale3d(0.9, 0.9, 0.9);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

.hasNotification {
	border: 2px solid $red;
}

.notificationText{
	color:$red;
	font-size:12px;
}