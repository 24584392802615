@import "../assets/styles/mixins.scss";

.container {
	position: relative;
	margin: 40px auto 0;
	width: calc(100% - 80px);
	height: calc(100vh - 80px);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	background: #F2F2F2;
	padding: 90px 40px 58px;
	box-sizing: border-box;
	max-width: 1285px;
	overflow-x: hidden;
}

/*Remove When header is gone*/
header {
	background: none !important;
	border: 0 !important;
	padding: 0 !important;
}
/*-----*/

.logo {
	position: absolute;
	top: 40px;
	left: 40px;
}

.helplink {
	position: absolute;
	top: 40px;
	right: 40px;
	font-size: 16px;

	button {
		color: #1B99D6;
		text-decoration: none;
		font-weight: bold;
	}
}

