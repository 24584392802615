@import "../../../assets/styles/mixins.scss";
@import "~maples-styleguide/sass/buttons.scss";

.taxResidencies {
	grid-template-columns: auto 500px!important;

	.residenceTable {
		background: white;
		border: 1px solid #ddd;
		border-collapse: separate;
		border-radius: 10px;

		th, td {
			padding: 16px 10px;

			&:first-child {
				padding: 16px 10px 16px 20px;
			}

			&:last-child {
				padding: 16px 20px 16px 10px;
			}
		}

		th {
			border-bottom: 0;
			color: $textSeconday;
			font-size: 14px;
			line-height: 16px;
		}

		td {
			border: 0;
		}

		.buttonCell {
			> button {
				position: relative;
				top: 3px;
			}
		}
	}

	.addButton {
		margin-bottom: 20px;
	}
}