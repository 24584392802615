@import "~maples-styleguide/sass/mixins.scss";
@import "../../assets/styles/mixins.scss";

.node {
	border: 2px solid $buttonBackground;
	border-radius: 35px;
	position: relative;
	z-index: 1;

	.container {
		align-items: center;
		background: white;
		border: 2px solid #{var(--nodeColour)};
		border-radius: 35px;
		color: #{var(--nodeColour)};
		cursor: pointer;
		display: flex;
		min-height: 54px;
		justify-content: space-between;
		padding: 5px;
		position: relative;
		width: 200px;
		z-index: 2;
	}

	.circle, .icon {
		align-items: center;
		border: 2px solid #{var(--nodeColour)};
		border-radius: 50%;
		display: flex;
		height: 40px;
		justify-content: center;
		min-width: 40px;
		width: 40px;
	}

	&.Completed {
		.circle:first-child {
			animation: growIn 0.7s cubic-bezier(0.39, 1.78, 0.87, 1) forwards;
			animation-delay: 1s;
			background: #{var(--nodeColour)};
			transform: scale(0);

			.tickline {
				stroke-linecap: round;
				stroke-linejoin: round;
				stroke-width: 3px;
				stroke-dasharray: 21;
				stroke-dashoffset: 21;
				animation: drawTick 0.4s ease-out forwards;
				animation-delay: 1.2s;
			}
		}
	}


	&.HasErrors {
		.container {
			border-color: $red;

			.circle:first-child {
				animation: growIn 0.7s cubic-bezier(0.39, 1.78, 0.87, 1) forwards;
				animation-delay: 1s;
				background: white;
				border-color: $red;
				transform: scale(0);
			}
		}

		.active {
			border: 2px solid $red;
			border-radius: 35px;

			.container {
				border-color: white;

				.circle:first-child {
					background: white;
					border-color: $red;
				}
			}
		}
	}

	&.active {
		.container {
			color: white;
			cursor: default;
			--c0: #{var(--nodeColour)};
			--c1: #ffffff;
			background: radial-gradient( circle at var(--x, 0) var(--y, 0), #{var(--gc0, var(--c0))} #{var(--stop, 0%)}, #{var(--gc1, var(--c1))} 0);

			.circle {
				-webkit-background-clip: text;
				background-clip: text;
				border-color: white;
			}

			@supports(mix-blend-mode:screen) {
				.nodeName {
					background: inherit;
					-webkit-background-clip: text;
					background-clip: text;
					color: transparent;
					filter: invert(1) grayscale(1) contrast(10);
				}
			}
		}

		&.Completed {
			.circle:first-child {
				background: white;
			}
		}

		&:not(.dialogOpen) {
			.menuButton {
				display: inline-flex;
				opacity: 1;
				right: -53px;

				&.editButton {
					top: -25px;
				}

				&.removeButton {
					bottom: -28px;
				}
			}
		}
	}

	.nodeName {
		padding: 0 10px;
		display: -webkit-box;
		line-height: 18px;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		word-break: break-word;
		overflow: hidden;
		padding: 0 50px 0 45px;
		position: absolute;
		user-select: none;
	}

	.name {
		flex: 1;
		padding: 0 10px;
	}
}

.emptyNode {
	.container, .circle {
		animation-direction: alternate;
		border: 2px dashed $borderColour;
	}

	&.pulse {
		.container {
			animation: pulse 0.75s ease-in-out infinite;
			border: 2px solid $red;
		}

		.circle {
			border-color:$red;
			color: $red;
		}

		.nodeName {
			color: $red;
		}
	}

	.circle {
		color: $borderColour;
		font-size: 24px;
	}

	&.active{
		.circle{
			color:white;
		}
	}

	.nodeName {
		color: $borderColour;
	}

	&.HasErrors {
		.circle {
			color: $red;
		}

		.nodeName {
			color: $red;
		}
	}
}

.menuButton {
	align-items: center;
	background: white;
	border: 2px solid #{var(--nodeColour)};
	border-radius: 50%;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	color: #{var(--nodeColour)};
	cursor: pointer;
	display: inline-flex;
	height: 40px;
	justify-content: center;
	opacity: 0;
	position: absolute;
	right: 0;
	transition: right 0.3s ease-in-out, top 0.3s ease-in-out, opacity 0.3s ease-in-out, bottom 0.3s ease-in-out;
	width: 40px;

	&:hover {
		background-color: #{var(--nodeColour)};
		color: white;
	}
}

.editButton{
	top:0;
}

.removeButton {
	bottom: 0;
}

.menuIcon:before{
  vertical-align:top;
}

.InProgress {
	.container {
		.circle:first-child {
			background: white;
		}
	}
}

@keyframes menu {
	from {
		stroke-dashoffset: 1000;
	}

	to {
		stroke-dashoffset: 0;
	}
}

@keyframes spinIn {
	0% {
		transform: scale(0) rotate(0)
	}

	100% {
		transform: scale(1) rotate(360deg)
	}
}

@keyframes growIn {
	0% {
		transform: scale(0)
	}

	100% {
		transform: scale(1)
	}
}

@keyframes drawTick {
	from {
		stroke-dashoffset: 21;
	}

	to {
		stroke-dashoffset: 0;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(1.2, 1.2);
	}

	100% {
		transform: scale(1, 1);
	}
}