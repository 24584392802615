@import "mixins.scss";
@import "~maples-fontface/css/maples/sass/maples-fontface.scss";
@import "~maples-styleguide/sass/buttons.scss";

html, body {
	font-size: 16px;
	height: 100%;
	line-height: 1.5rem;
	text-rendering: optimizeLegibility;
}

body {
	color: $textSeconday;
	font-family: 'Roboto', arial, Helvetica, Sans-Serif !important;
	font-size:14px;
}

h1, h2, h3 {
	color: $textMain;
	font-family: 'Lato-Regular', arial, Helvetica, Sans-Serif !important;
	font-size: 54px;
	font-weight: bold;
	line-height: 54px;
}

h2 {
	font-size: 36px;
	line-height: 44px;
}

h3 {
	font-size: 24px;
	line-height: 24px;
}

h4 {
	color: $textMain;
	font-size:20px;
	font-weight:normal;
	line-height:25px;
}

.smallText {
	font-size: 14px;
}

article, .stepCount {
	color: $textSeconday;
	font-size: 14px;
	line-height: 1.6em;
}

.overflow {
	overflow: auto;
}

.iconTextButton{
  text-transform:uppercase;
}
.downloadButton {
	display: block;
	box-sizing: border-box;
	border: 2px solid $primaryColor;
	padding: 20px;
	color: $primaryColor;
	border-radius: 10px;
	background-color: #FFF;
	text-decoration: none;
	width: 100%;

	&:before {
		display: inline-block;
		content: "";
		font-family: $iconFont;
		height: 40px;
		width: 36px;
		font-size: 40px;
		line-height: 40px;
		margin-right: 20px;
		vertical-align: middle;
	}
}

.col-1{
  width:100%;
}

@import "stepper.scss";

@import "materialoverrides.scss";

@import "animations.scss";
