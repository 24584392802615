/*Material-UI Overrides*/
body {

	.stepperDialog {
		.MuiCheckbox-root {
			padding: 0 9px 0 0;
		}

		.MuiStepper-root {
			padding: 0 24px;
		}
	}

	.MuiDialogActions-root {
		padding: 20px;
	}

	.MuiFormGroup-root {
		label:last-child {
			margin-bottom: 0;
		}
	}
}
