@import "../../../../assets/styles/mixins.scss";


.nextButton {
	background: none;
	border: 1px solid $primaryColor;
	border-radius: 5px;
	box-shadow: none;
	color: $primaryColor;

	&::after {
		content: '';
	}

	&:hover {
		background: none;
		box-shadow: none;
		color: $textMain;
	}

	.nextArrow, .nextArrowCopy {
		height: 20px;
		margin-left: 5px;
		width: 20px;
	}
}

.nextArrowCopy {
	left: 50px;
	opacity: 0;
	position: absolute;
}

.nextButton.activeButton {
	animation: buttonFill 1.5s;
	animation-fill-mode: forwards;

	.nextArrow {
		animation: slideOutArrow 10s infinite;
		animation-timing-function: ease-in;
	}

	.nextArrowCopy {
		animation: slideInArrow 10s infinite;
		animation-delay: 0.5s;
		animation-timing-function: ease-out;
	}
}

@keyframes buttonFill {
	0% {
		background-color: white;
		color: $primaryColor;
	}

	100% {
		background-color: $primaryColor;
		color: white;
	}
}

@keyframes slideInArrow {
	0% {
		transform: translateX(-50%);
		opacity: 0;
	}

	2.5% {
		transform: translateX(0);
		opacity: 1;
	}

	44% {
		transform: translateX(0);
		opacity: 1;
	}

	44.01% {
		transform: translateX(-50%);
		opacity: 0;
	}

	100% {
		transform: translateX(-50%);
		opacity: 0;
	}
}

@keyframes slideOutArrow {
	0% {
		transform: translateX(0);
		opacity: 1;
	}

	2.5% {
		transform: translateX(50%);
		opacity: 0;
	}

	49% {
		transform: translateX(50%);
		opacity: 0;
	}

	49.01% {
		transform: translateX(0);
		opacity: 1;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
