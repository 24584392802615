@import "../../assets/styles/mixins.scss";

.dataProtectionContainer {
	display: grid;
	grid-template-rows: 60px auto;

	.stepDetails1col {
		grid-column: 1 / -1;
		height: 100%;

		> div {
			display:grid;
			grid-template-rows:minmax(0, auto) minmax(0, auto) minmax(0, 1fr) minmax(0, auto);
			max-height:100%
		}
		article{
			overflow:auto;
		}
	}
}
h1 {
	margin-top: 0 !important;
}
.checkBox {
	border: 1px dashed $inActive;
	border-radius: 10px;
	margin: 40px 0 0 0 !important;
	padding: 20px 35px 20px 20px;
}
.isChecked {
	border: 1px solid $green;

	.checkBoxLabel {
		color: $green;
	}
}


