.radioSection {
	border: 1px dashed $inActive;
	border-radius: 10px;
	margin-left: 0 !important;
	padding: 15px 10px 15px 0;

	&:before {
		content: '';
		position: absolute;
		top: -1px;
		left: -1px;
		z-index: -1;
		width: 100%;
		height: 100%;
		border: 1px solid $borderColour;
		border-radius: 10px;
		opacity: 0;
		transition: 0.5s opacity ease-in-out;
	}

	&.active {
		color: $textMain;

		&:before {
			opacity: 1;
		}
	}
}
