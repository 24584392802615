@import "../../../assets/styles/mixins.scss";

.container {
	align-items: center;
	border: 1px dashed $borderColour;
	border-radius: 10px;
	display: grid;
	grid-template-columns: 27px auto 54px;
	column-gap: 20px;
	line-height: 20px;
	padding: 20px;
	position: relative;
	transition: 0.5s filter ease-in-out;

	.circle {
		color: $green;
		filter: grayscale(100%);
		font-size:36px;
		transform: scale(1);
		transition: 0.5s filter ease-in-out;

		> div {
			position: absolute;
			left: 0;
			top: -1px;
			opacity: 0;
		}

		&:before {
			transition: 0.5s opacity ease-in-out;
		}

		&.info {
			color: #AC621C;
			filter: none;
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: -1px;
		left: -1px;
		width: 100%;
		height: 100%;
		border: 1px solid $borderColour;
		border-radius: 10px;
		opacity: 0;
		transition: 1s opacity ease-in-out;
	}

	&.active {
		&:before {
			opacity: 1;
		}

		.circle {
			filter: grayscale(0);
			animation-name: bounceIn;
			animation-duration: 0.5s;
		}

		svg {
			filter: grayscale(0);
			opacity: 1;

			.sun {
				transform: translate(20px, -5px);
			}
		}

		.dropTitle {
			transform: translateY(7px);
		}

		.dropText {
			opacity: 0;
		}

		.notesButton {
			transform: translateY(-7px);
		}
	}

	svg {
		filter: grayscale(100%);
		opacity: 0.3;
		transition: 0.5s filter ease-in-out, 0.5s opacity ease-in-out;

		.sun {
			transition: 0.5s transform ease-in-out;
		}
	}
}

.dragover {
	svg {
		filter: grayscale(0);
		opacity: 1;

		.sun {
			transform: translate(20px, -5px);
		}
	}

	input {
		z-index: 2 !important;
	}
}

.dropped {
	svg {
		filter: grayscale(0);
		opacity: 1;
	}

	.circle {
		&:before {
			opacity: 0;
		}

		> div {
			opacity: 1;
		}
	}
}

.notesButton {
	font-size:14px;
	margin-right: 20px;
	margin-top: 5px;
	position: relative;
	z-index: 2;
	transition: 0.5s transform ease-in-out;
}

.circle {
	color: $textSeconday;
	position: relative;
	top: 3px;

	&.tickcircle {
		color: $green
	}
}

.dropTitle {
	display: block;
	transition: 0.5s transform ease-in-out;
}

.dropText {
	display: block;
	font-size: 12px;
	transition: 0.5s opacity ease-in-out;
}

.idIcon svg{
  margin-left:11px;
}
@keyframes bounceIn {
	from, 33%, 66%, to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	33% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1);
		transform: scale3d(1.1, 1.1, 1.1);
	}

	66% {
		-webkit-transform: scale3d(0.9, 0.9, 0.9);
		transform: scale3d(0.9, 0.9, 0.9);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}