@import "../../../assets/styles/animations.scss";

.celebrationLayout {
	grid-column-gap: 80px;
	column-gap: 80px;
	display: grid;
	grid-template-columns: auto calc(50% - 40px);
	grid-row-gap: 40px;
	align-items: center;
	height: 100%;

	> div {
		opacity: 0;
		animation-name: slideInFromRight;
		animation-duration: 0.4s;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;
	}

	> div + div {
		width: 90%;
		animation-delay: 200ms;

		&:before, &:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 20px;
			background: linear-gradient(0deg, rgba(242,242,242,0) 0%, rgba(242,242,242,1) 100%);
			z-index: 1;
		}

		&:after {
			top: auto;
			bottom: 0;
			background: linear-gradient(180deg, rgba(242,242,242,0) 0%, rgba(242,242,242,1) 100%);
		}
	}
}

.celebrationimage {
	width: 90%;
	vertical-align: central;
}

.intro {
	display: grid;
	grid-template-rows: auto minmax(0, 1fr) auto;
	max-height: 100%;
	min-height: 0;

	> h1 {
		margin-bottom: 40px;
	}

	h1, p {
		span {
			color: #1B99D6;
		}
	}

	.content {
		margin-bottom: 40px;
		min-height: 0;
		overflow: auto;
	}

	p {
		color: #717171;
		font-size: 22px;
		font-weight: 300;
		line-height: 26px;
		margin: 0 0 20px;
	}
}

.confetti {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
