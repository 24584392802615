.stepContent {
	display: grid;
	grid-template-columns: auto 400px;
	grid-column-gap: 50px;
	grid-row-gap: 40px;
	grid-template-rows: auto minmax(0,calc(100vh - 355px));
	max-height: 100%;

	h1 {
		margin-bottom: 0;
		margin-top: 0;
		grid-column: 1/3;
	}

	p {
		margin: 0;
	}

	> * {
		align-self: center;
		animation-name: slideInFromRight;
		animation-duration: 0.5s;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;
		height: 100%;
		opacity: 0;

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				animation-delay: 200ms * $i;
			}
		}
	}

	h1 + div {
		animation-name: slideInFromBottom;
	}

	.stepLeft {
		align-items: center;
		align-self: auto;
		display: flex;
		overflow-x: hidden;
		overflow-y: auto;

		> h2 {
			margin: 20px 0;
		}

		.overflowText {
			box-sizing: border-box;
			display: grid;
			grid-template-rows: auto 1fr;
			max-height: 100%;

			article {
				height: 100%;
				overflow: auto;
			}
		}
	}

	.stepRight {
		align-self: auto;
		overflow-x: hidden;
		overflow-y: auto;

		.rowWrap {
			display: grid;
			grid-template-rows: auto;
			grid-row-gap: 20px;

			> * {
				animation-name: slideInFromRight;
				animation-duration: 0.5s;
				animation-timing-function: ease-in-out;
				animation-fill-mode: forwards;
				opacity: 0;

				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						animation-delay: 300ms * $i;
					}
				}
			}
		}
	}
}

.stepwrap {
	display: grid;
	grid-template-rows: 1fr auto;
	height: 100%;
}

.stepwrap.next {
	.stepContent {
		animation-name: slideOutToLeft;
		animation-duration: 0.5s;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;
	}
}

.stepwrap.prev {
	.stepContent {
		animation-name: slideOutToRight;
		animation-duration: 0.5s;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;

		> *, .stepRight .rowWrap > * {
			opacity: 1;
			animation-name: slideInFromLeft;
		}
	}
}

.stepwrap.postprev {
	.stepContent {
		animation-name: slideInFromLeft;
		animation-duration: 0.5s;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;

		> *, .stepRight .rowWrap > * {
			animation-name: slideInFromLeft;
		}

		h1 + div {
			animation-name: slideInFromBottom;
		}
	}
}

.stepDetails {
	align-items: center;
	display: grid;

	h2 {
		margin-top: 10px;
	}
}

.stepCount {
	font-size: 24px;
	font-weight: bold;
	margin: 0;
}
