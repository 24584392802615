@import "../../../../assets/styles/formGrid.scss";

.textarea {
	height: 170px;
}

.pepButton {
	display: flex;
}

.documentContainer {
	margin-top: 20px;
}