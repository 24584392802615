.container{
  align-items:center;
  column-gap:40px;
  display:grid;
  grid-template-columns:1fr 1fr;
  height:100%;
}

.img{
  width:500px;
}

.details {
	margin-top:20px;
	white-space: pre-wrap;
	summary{
	  outline:none;
	}
}