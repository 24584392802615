@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/breakpoints.scss";

.h1{
  margin-top:0;
}

.stepButtons {
	align-items: center;
	display: grid;
	grid-template-columns: 70px 90px;
	justify-content: space-between;
	column-gap: 40px;
	width: 100%;

	@include to($breakpoint-md) {
		grid-column: 1;
	}

	> div {
		overflow: auto;
	}

	button {
		height: fit-content;
	}
}

.backArrow {
	display: inline-block;
	font-family: $iconFont;
	transform: rotate(-90deg);
	margin-right: 3px;
}

.container {
	column-gap: 100px;
	display: grid;
	grid-template-columns: auto;
	padding: 100px 100px 40px !important;
	position: relative;
	grid-row-gap: 40px;

	@include to($breakpoint-md) {
		column-gap: 20px;
		grid-template-columns: 1fr;
		padding: 60px !important;
	}

	@include to($breakpoint-sm) {
	}

	.closeButton {
		position: absolute;
		top: 20px;
		right: 20px;
	}

	.MuiStepper-root {
		padding: 0 24px;
	}
}

.sideDialogWrap {
	opacity: 0 !important;
}

.sideDialog {
	margin-left: 320px !important;

	.dialogContent {
		display: flex;
		height: calc(100vh - 295px);
		padding: 45px !important;

		.closeButton {
			position: absolute;
			top: 20px;
			right: 20px;
		}
	}
}


