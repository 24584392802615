@import "./mixins.scss";

.tourGuide {
	background-color: #323549 !important;
	color: #FFF !important;
	margin-top: 15px;
	animation: fadeIn 1s ease-in-out forwards;
}

.tourright, .tourleft {
	&:before {
		content: '';
		width: 0;
		height: 0;
		border-top: 13px solid transparent;
		border-bottom: 13px solid transparent;
		border-left: none;
		border-right: 15px solid #323549;
		position: absolute;
		top: 35px;
		left: -14px;
	}

	.buttonwrap {
		text-align: right;

		.tourbutton {
			color: #FFF;
		}

		.tourbuttonPrimary {
			color: #FFF;
			background: $primaryColor;
		}
	}

	p {
		margin-top: 0;
	}
}

.tourleft {
	&:before {
		border-top: 13px solid transparent;
		border-bottom: 13px solid transparent;
		border-right: none;
		border-left: 15px solid #323549;
		position: absolute;
		left: auto;
		right: -14px;
	}
}

.shiftUp20 {
	&:before {
		top: 24px;
	}
}

.tourMask {
	opacity: 0;
}
